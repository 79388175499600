import React, { useEffect, useState } from "react";
import "./chat.css";
import CryptoJS from "crypto-js";
import { ImagePaths, Logo, Logo_round } from "../../../Constants/Images/Images";
import { Sessions } from "../../../Constants/Sessions";
import { PostRequestForm } from "../../../Network/ApiRequests";
import { API_ROUTES } from "../../../Constants/apiRouts";

export default function Chats({ setMuser, new_message }) {
  const [searchText, setSearchText] = useState("");
  const [user, setUser] = useState("");

  const [data, setData] = useState([]);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    console.log("Search for:", searchText);
  };

  useEffect(() => {
    if (new_message != null && new_message != "") {
      //console.log(new_message);
      /* {
        "chat": {
            "attachment": "",
            "attachment_me": "",
            "created_at": "2024-06-19 15:09:16",
            "is_deleted": "0",
            "id": "1163275059",
            "message": "Dhr",
            "reciver": "1",
            "replay": "",
            "replay_of": "",
            "rid": 0,
            "seen": "0",
            "sender": "2",
            "sender_name": "somnath",
            "thread": "21",
            "type": "T",
            "work_id": "aa4d90ba-c2e9-4bb4-8623-a60e7a031db3"
        },
        "msg": "live android"
    } */

      /* data.forEach((item) => {
        if (item?.threadId == new_message.thread) {
          item.last_message = new_message.message;
          item.last_message_sender = new_message.sender;
          item.last_message_type = new_message.type;
        }
      });

      setData([...data]); */

      const indexToUpdate = data.findIndex(item => item.threadId === new_message.thread);

      if (indexToUpdate !== -1) {
        // Update the properties of the item
        data[indexToUpdate].last_message = new_message.message;
        data[indexToUpdate].last_message_sender = new_message.sender;
        data[indexToUpdate].last_message_type = new_message.type;
  
        // Move the updated item to the top
        const updatedItem = data[indexToUpdate];
        const newData = [
          updatedItem,
          ...data.slice(0, indexToUpdate),
          ...data.slice(indexToUpdate + 1)
        ];
  
        // Update state to trigger re-render
        setData(newData);

      }




    }
  }, [new_message]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem(Sessions.User)));
  }, []);

  useEffect(() => {
    if (user != null && user != "") {
      const uthHeader = user?.email;
      const formData = new FormData();
      formData.append("id", user?.id);

      PostRequestForm(
        API_ROUTES.GET_ALL_THREAD,
        formData,
        null,
        uthHeader,
        (res) => {
          if (res.error) {
          } else {
            //console.log(res);
            if (res.code == "-200") {
              setData(res?.threads);
            }
          }
        }
      );
    }
  }, [user]);

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase())
  );
  const RowRenderer = ({ user }) => {
    return (
      <div
        className="list-item-container"
        onClick={() => {
          if (setMuser) {
            setMuser(user);
          }
        }}
      >
        <img
          src={API_ROUTES.PROFILE_PIC + user?.profile_pic}
          alt="image"
          className="user-image"
        />
        <div className="user-data">
          <text className="name">{user.name}</text>
          <text className="email">
            {user?.last_message_type
              ? user?.last_message_type == "T"
                ? decrypt(user?.last_message_sender, user?.last_message)
                : user?.last_message
              : ""}
          </text>
        </div>
      </div>
    );
  };

  const encrypt = (passphrase, data) => {
    const key = getKey(passphrase);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      mode: CryptoJS.mode.ECB,
    });
    return encrypted.toString();
  };
  const getKey = (passphrase) => {
    return CryptoJS.SHA256(passphrase);
  };
  // Decrypt a string
  const decrypt = (passphrase, encryptedData) => {
    const key = getKey(passphrase);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      mode: CryptoJS.mode.ECB,
    });
    const str = decrypted.toString(CryptoJS.enc.Utf8);
    return str == "" ? encryptedData : str;
  };

  return (
    <div>
      <div className="search-box">
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleInputChange}
        />
        <button className="search-button" onClick={handleSearch}>
          <img
            src={ImagePaths.search.default}
            style={{ height: "26px", width: "15px" }}
          />
        </button>
      </div>
      {/* list */}
      {filteredData.map((user, index) => {
        return <RowRenderer key={index} user={user} index={index} />;
      })}
    </div>
  );
}
