import CryptoJS from "crypto-js";
import React, { useEffect } from "react";

 // Encrypt a string
 export const encrypt = (passphrase, data) => {
  const key = getKey(passphrase);
  const encrypted = CryptoJS.AES.encrypt(data, key, { mode: CryptoJS.mode.ECB });
  return encrypted.toString();
};
export const getKey = (passphrase) => {
  return CryptoJS.SHA256(passphrase);
};
// Decrypt a string
export const decrypt = (passphrase, encryptedData) => {
  const key = getKey(passphrase);
  const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { mode: CryptoJS.mode.ECB });
  return decrypted.toString(CryptoJS.enc.Utf8);
};



export default function Test() {
 
  useEffect(() => {
    const passphrase = '2';
    const originalString = 'Hello, World!';


    const encrypted = encrypt(passphrase, originalString);
    console.log("encrypted:", encrypted);
    const decrypted = decrypt(passphrase, encrypted);
    console.log("Decrypted:", decrypted);
  }, []);

  return <div>test</div>;
}
