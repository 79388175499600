import moment from "moment-timezone";

export const FormateDate = (dateStr) => {
    const date = moment.utc(dateStr, 'YYYY-MM-DD HH:mm:ss');

    // Convert to the local time zone
    const localDate = date.local();
  
  // Format the date
  return localDate.format('Do MMMM YYYY, h:ma');
};

export const CurrentDate = () => {
  const nowUTC = moment().tz("UTC");
  return nowUTC.format("YYYY-MM-DD HH:mm:ss");
};
