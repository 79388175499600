import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link   } from 'react-router-dom';
import './App.css';
import SplashScreen from './screens/Splash';
import About from './screens/Home/About';
import Contact from './screens/Home/Contact';
import Home from './screens/Home/Home';
import Login from './screens/auth/Login';
import Signup from './screens/auth/Signup';
import OtpVerification from './screens/auth/OtpVerification';
import SetPassword from './screens/auth/SetPassword';
import ForgotPassword from './screens/auth/ForgotPassword';
import Dashboard from './screens/Home/Dashboard';
import Messaging from './screens/Home/Messages/Messaging';
import Test from './screens/test';
const App = () => {
  return (
    <div className="App">
    
        <Router>
          <Routes >
            <Route path="/" element={<SplashScreen />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/home" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify" element={<OtpVerification />} />
            <Route path="/setpassword" element={<SetPassword />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/test" element={<Test />} />
           {/*  <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/messages" element={<Messaging />} /> */}
          </Routes>
        </Router>
      
    </div>
  );
};

export default App;
