// apiRoutes.js

const Home_URL = "https://easysent.in/";
const BASE_URL = Home_URL + "api/Easysent/api/";
const Attachment_URL = "https://attachment.easysent.in/";

export const API_ROUTES = {
  REGISTER_USER: BASE_URL + "register_user.php",
  EMAIL_VERIFY: BASE_URL + "verify.php",
  RESEND_OTP: BASE_URL + "resend_otp.php",
  LOGIN: BASE_URL + "login.php",
  FORGOT_PASSWORD: BASE_URL + "forgot_password.php",
  SET_PASSWORD: BASE_URL + "set_password.php",
  EDIT_PROFILE: BASE_URL + "edit_profile.php",
  GET_USER: BASE_URL + "get_user.php",
  GET_ALL_USER: BASE_URL + "get_all_user.php",
  GET_ALL_THREAD: BASE_URL + "getallActiveThread.php", //"get_all_thread.php",
  SEND_MESSAGE: BASE_URL + "send_message.php",
  GET_ALL_CHATS: BASE_URL + "get_all_chats.php",
  GET_ALL_CHATS_BY_THREAD: BASE_URL + "get_all_chats_by_thread.php",
  UPDATE: BASE_URL + "update.php",
  SEND_NOTIFICATION: "https://fcm.googleapis.com/fcm/send",
  TEST: Home_URL + "api/test.php",
  ACTIVE: "",
  CATCH_DIR_CASH: "", //catch_Dir/easysent
  CATCH_DIR_Memory: "", //storage sd_card/Easysent
  CATCH_DIR3: "", //catch_Dir/easysent/cash
  UPDATE_BY_SQL: BASE_URL + "update_by_sql.php",
  CREATE_CLIP: BASE_URL + "create_clip.php",
  GATE_ALL_CLIP: BASE_URL + "Clip/get_clip.php",
  BLOCK_USER: BASE_URL + "block_user.php",
  GET_BLOCK_USER: BASE_URL + "get_block_users.php",
  LIKE_CLIPS: BASE_URL + "Clip/like.php",
  GET_IP: BASE_URL + "get_ip.php",
  Create_group: BASE_URL + "Group/create_group.php",
  Get_group: BASE_URL + "Group/get_groups.php",
  SEND_GROUP_MESSAGE: BASE_URL + "Group/send_message.php",
  GET_ALL_GROUP_MESSAGE: BASE_URL + "Group/get_all_chats.php",
  GET_MESSAGE_By_GROUP: BASE_URL + "Group/get_chat_by_group.php",
  LEFT_GROUP: BASE_URL + "Group/left_group.php",
  UPDATE_Group: BASE_URL + "Group/update_group.php",
  GET_GROUP_USERS: BASE_URL + "Group/get_group_user.php",
  JOIN_USERS: BASE_URL + "Group/join_user.php",
  LOGIN_HISTORY: BASE_URL + "get_login_history.php",
  LOGOUT: BASE_URL + "logout.php",
  PROFILE_PIC: BASE_URL + "profile_image/",
};

export const URLS = {
  Home_URL,
  BASE_URL,
  Attachment_URL,
};
