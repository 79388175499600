import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/login.css";
import { Logo, Logo_round } from "../../Constants/Images/Images";
import Alert from "../../Components/Alert";
import { PostRequestForm } from "../../Network/ApiRequests";
import { API_ROUTES } from "../../Constants/apiRouts";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "", show: false });

  const navigate = useNavigate();

  const showAlert = (type, message, callback) => {
    setAlert({ type, message, show: true });
    setTimeout(() => {
      //setAlert({ type: "", message: "", show: false });
      if (callback) {
        callback();
      }
    }, 1000); // Auto close after 3 seconds
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Perform your authentication logic here (e.g., API call, validation)
    // For simplicity, let's check if username and password are not empty
    if (username) {
      send();
    } else {
      showAlert("error", "Please enter valid credentials.");
    }
  };

  const send = () => {
    const formData = new FormData();
    formData.append("email", username);

    PostRequestForm(API_ROUTES.FORGOT_PASSWORD, formData, null, "", (res) => {
      if (res.error) {
        showAlert("error", res.error);
      } else {
        console.log(res);
        if (res.code == "-200") {
          showAlert("success", res.success, () => {
            const userData = {
              from: "forgot",
              email: username,
              code: res.otp,
            };

            navigate("/verify", { state: { userData } });
          });
        }
      }
    });
  };

  return (
    <div className="login-container">
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ type: "", message: "", show: false })}
        />
      )}
      <div className="login-content">
        <div className="login-form">
          <img src={Logo_round} alt="App Logo" className="app-logo" />
          <h2 className="login-heading">ForgotPassword</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <input
                type="email"
                id="passwoed"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="login-input"
                placeholder="Enter Email address"
                required
              />
            </div>

            <button type="submit" className="login-button">
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
