import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/login.css";
import { Logo, Logo_round } from "../../Constants/Images/Images";
import Alert from "../../Components/Alert";
import { PostRequestForm } from "../../Network/ApiRequests";
import { API_ROUTES } from "../../Constants/apiRouts";

const SetPassword = () => {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "", show: false });
  const location = useLocation();
  const navigate = useNavigate();

  const showAlert = (type, message, callback) => {
    setAlert({ type, message, show: true });
    setTimeout(() => {
      //setAlert({ type: "", message: "", show: false });
      if (callback) {
        callback();
      }
    }, 1000); // Auto close after 3 seconds
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Perform your authentication logic here (e.g., API call, validation)
    // For simplicity, let's check if username and password are not empty
    if (password && cpassword) {
      if (password == cpassword) {

        if(password.length>8){
            setPass();
        }else{
          showAlert('error',"password must be greater than 8 Charcter");
        }
        
      } else {
        showAlert('error',"password And Confirm Password Must be same");
      }
    } else {
      showAlert('error',"Please enter valid credentials.");
    }
  };


  const setPass = ()=>{
    const mail = location?.state?.userData?.email;
    if(!mail){
      showAlert('error',"mail not found");
        return
    }
    const formData = new FormData();
    formData.append("email",mail );
    formData.append("password", password);

    PostRequestForm(API_ROUTES.SET_PASSWORD, formData, null, "", (res) => {
      if (res.error) {
        showAlert("error", res.error);
      } else {
        //console.log(res);
        if (res.code == "-200") {
          showAlert("success", res.success, () => {
            navigate("/login");
          });
        }
      }
    });
  }

  return (
    <div className="login-container">
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ type: "", message: "", show: false })}
        />
      )}
      <div className="login-content">
        <div className="login-form">
          <img src={Logo_round} alt="App Logo" className="app-logo" />
          <h2 className="login-heading">Reset Password</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <input
                type="text"
                id="passwoed"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
                placeholder="Enter new password"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="conpassword"
                value={cpassword}
                onChange={(e) => setCPassword(e.target.value)}
                className="login-input"
                placeholder="Confirm your password"
                required
              />
            </div>
            <button type="submit" className="login-button">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
