import React from "react";
import { ImagePaths } from "../../../Constants/Images/Images";

class PDFViewr extends React.Component {
  render() {
    return (
      <div className="pdf">
        <a href={this.props.pdf} target="_blank" rel="noopener noreferrer">
          <img
            src={ImagePaths.open.default }
            style={{
              width: "15px", // Adjust width and height as needed
              height: "15px", // Maintain aspect ratio
              cursor: "pointer",
              position:'absolute',
              right:15
            }}
            alt="open Link"
          />
        </a>
        <embed src={this.props.pdf} width="300px" height="300px" />
      </div>
    );
  }
}

export default PDFViewr;
