import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/login.css";
import { Logo, Logo_round } from "../../Constants/Images/Images";
import Alert from "../../Components/Alert";
import { PostRequestForm } from "../../Network/ApiRequests";
import { API_ROUTES } from "../../Constants/apiRouts";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmil] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setCpassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState({ type: "", message: "", show: false });

  const showAlert = (type, message, callback) => {
    setAlert({ type, message, show: true });
    setTimeout(() => {
      //setAlert({ type: "", message: "", show: false });
      if (callback) {
        callback();
      }
    }, 1000); // Auto close after 3 seconds
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (verify()) {
      signup();
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // Validate input to allow only up to 5 digits
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
    }
  };

  const verify = () => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (re.test(String(email).toLowerCase())) {
    } else {
      showAlert("error", "Enter Valid Email Address");
      return false;
    }

    if (/^\d{0,10}$/.test(phone)) {
    } else {
      showAlert("error", "Enter Valid mobile number");
      return false;
    }

    if (password != c_password) {
      showAlert("error", "password And Confirm Password Must be same");
      return false;
    } else if (password.length < 8) {
      showAlert("error", "password must be greater than 8 Charcter");
      return false;
    }

    return true;
  };
  const navigators=(path)=>{
    navigate(path);
  }
  const signup = () => {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("username", username);
    formData.append("c_password", c_password);

    PostRequestForm(API_ROUTES.REGISTER_USER, formData, null, "", (res) => {
      if (res.error) {
        showAlert("error", res.error);
      } else {
        //console.log(res);
        if (res.code == "-200") {
          showAlert("success", res.success, () => {
            const userData = {
              email: email,
            };
  
            navigate("/verify", { state: { userData } });
          });
        }
      }
    });
  };

  return (
    <div className="login-container">
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ type: "", message: "", show: false })}
        />
      )}

      <div className="login-content">
        <div className="login-form">
          <img src={Logo_round} alt="App Logo" className="app-logo" />
          <h2 className="login-heading">Signup</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="login-input"
                placeholder="Enter your Name"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmil(e.target.value)}
                className="login-input"
                placeholder="Enter your Email Address"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                id="phone"
                value={phone}
                onChange={handleChange}
                className="login-input"
                placeholder="Enter your Phone Number"
                required
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="login-input"
                placeholder="Enter your username"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
                placeholder="Enter your password"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="cpassword"
                value={c_password}
                onChange={(e) => setCpassword(e.target.value)}
                className="login-input"
                placeholder="Confirm password"
                required
              />
            </div>
            <button type="submit" className="login-button">
              Signup
            </button>
          </form>
          <p className="signup-link">
            Already have an account? <a onClick={()=>{navigators("/login")}}>Login Now</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
