import React, { useEffect, useState, useCallback } from "react";
import "../../css/Dashboard.css";
import { Cross, ImagePaths } from "../../Constants/Images/Images";
import Theme from "../../Constants/Theme";
import Users from "./Users/Users";
import Chats from "./Chats/Chats";
import Messaging from "./Messages/Messaging";
import io from "socket.io-client";
import EmptyChatContainer from "./Users/Empty/EmptyChatContainer";

//const ENDPOINT = "https://worldtoday.online:3000"; // Replace with your server endpoint
const ENDPOINT = "https://easysent.site:3000"; // Replace with your server endpoint

const Dashboard = ({ me }) => {
  const [activeTab, setActiveTab] = useState("User");
  const [showList, setShowList] = useState(false);
  const [user, setUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState(null);

  const handleButtonClick = (tab) => {
    setActiveTab(tab);
    setShowList(true);
  };

  useEffect(() => {
    if (me) {
      const newSocket = io(ENDPOINT);
      setSocket(newSocket);

      newSocket.on("connect", () => {
        console.log("CONNECTED SOCKET");
      });

      // Listen for messages from the server
      newSocket.on(me?.id, (message, callback) => {
        if (callback) {
          if (user && message.chat?.sender === user?.id) {
            callback("seen");
          } else {
            callback("done");
          }
        }
        setMessages(message.chat);
      });

      return () => {
        newSocket.close();
      };
    }
  }, [me]);

  // Memoized sendMessage function to avoid re-creating it on each render
  const sendMessage = useCallback((data, callback) => {
    if (data) {
      socket.emit("chat message", data, (response) => {
        callback && callback(response);
      });
    }
  }, [socket]);
console.log("render")
  return (
    <div className="dashboard">
      <div className="toolbar">
        <span
          className="toolbar-button"
          onClick={() => {
            setShowList(!showList);
          }}
        >
          <img
            src={
              showList ? ImagePaths.arrow_left.default : ImagePaths.bar.default
            }
            style={{
              width: "15px", // Adjust width and height as needed
              height: "15px", // Maintain aspect ratio
              cursor: "pointer",
            }}
            alt="s"
          />
        </span>
        <span
          className="toolbar-button"
          onClick={() => handleButtonClick("User")}
          style={{
            backgroundColor: activeTab === "User" ? "#888" : null,
          }}
        >
          <img
            src={ImagePaths.user.default}
            style={{ width: "15px", height: "auto" }}
            alt="s"
          />
          <span className="toolbar-button-text">User</span>
        </span>
        <span
          className="toolbar-button"
          onClick={() => handleButtonClick("Chat")}
          style={{
            backgroundColor: activeTab === "Chat" ? "#888" : null,
          }}
        >
          <img
            src={ImagePaths.chat.default}
            style={{
              width: "15px", // Adjust width and height as needed
              height: "auto", // Maintain aspect ratio
            }}
            alt="s"
          />
          <span className="toolbar-button-text">Chat</span>
        </span>
      </div>

      <div className={`horizontal-list ${showList ? "show" : ""}`}>
        {activeTab && (
          <span>
            <div style={{ display: activeTab === "Chat" ? "block" : "none" }}>
              <Chats setMuser={setUser} new_message={messages} />
            </div>
            <div style={{ display: activeTab === "User" ? "block" : "none" }}>
              <Users setMuser={setUser} />
            </div>
          </span>
        )}
      </div>
      <div className="content">
        {user ? (
          <Messaging
            user={user}
            me={me}
            newmess={messages?.sender === user?.id ? messages : null}
            sendMessagesock={sendMessage}
          />
        ) : (
          <EmptyChatContainer />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
