// Messaging.js
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Messaging.css";
import { API_ROUTES, URLS } from "../../../Constants/apiRouts";
import { PostRequestForm } from "../../../Network/ApiRequests";
import { CurrentDate, FormateDate } from "../../../Components/Helper";
import CryptoJS from "crypto-js";
import { ImagePaths } from "../../../Constants/Images/Images";

import PDFViewr from "./PDFViewr";
import StatusIcon from "./StatusIcon";
import VideoViewer from "./VideoViewer";

const Messaging = React.memo(({ user, me, newmess, sendMessagesock }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const [thread, setThread] = useState("");
  const messageListRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setselectedFileUrl] = useState(null);
  const handleFileInputChange = (event) => {
    // Handle file selection here
    const file = event.target.files[0];

    console.log(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setselectedFileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setselectedFileUrl(null);
    }

    setSelectedFile(file);
    // You can also perform additional operations like uploading the file if needed
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      const id = new Date().getTime();
      const date = CurrentDate();

      const newchat = {
        id: id.toString(),
        message: encrypt(me?.id, newMessage),
        type: selectedFile ? getFileType(selectedFile) : "T",
        attachment: selectedFile ? selectedFile?.name : "",
        attachment_title: selectedFile ? selectedFile?.name : "",
        sender: me?.id,
        sender_name: me?.name,
        reciver: user?.id,
        seen: "0",
        created_at: date,
        thread: thread,
        replay_of: "",
        replay: "",
        is_deleted: "0",
        attachment_my: selectedFile ? selectedFileUrl : "",
      };

      setMessages([...messages, newchat]);
      setNewMessage("");

      sendMessage(newchat, id, date); //to api
      if (newchat.type == "T") {
        sendMessageSocket(newchat, user?.id);
      }
      setSelectedFile(null);
      setselectedFileUrl(null);
    }
  };

  const getFileType = (file) => {
    if (file == null || file == undefined) {
      return "";
    }

    const fileType = file.type || file.name.split(".").pop().toLowerCase();

    if (fileType.startsWith("image/")) {
      return "I";
    } else if (fileType.startsWith("video/")) {
      return "V";
    } else if (fileType === "application/pdf") {
      return "P";
    } else if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "D";
    } else {
      return "Unknown";
    }
  };
  const updateMessageStatus = (chat, newStatus) => {
    chat.seen = newStatus;
    setMessages([...messages, chat]);
  };

  const sendMessageSocket = (chat, reciver) => {
    const data = {
      reciver: reciver,
      data: {
        chat: chat,
        msg: "live android",
      },
    };

    if (sendMessagesock) {
      sendMessagesock(data, ({ status, delivery }) => {
        //console.log("call", status); // Handle server callback
        if (delivery == "seen") {
          updateMessageStatus(chat, 3);
        } else {
          updateMessageStatus(chat, 2);
        }
      });
    }
  };

  const sendMessage = async (messge, id, date, forward = "") => {
    /*  val map = HashMap<String, Any>()
      map["sender"] = sender
      map["is_1st"] = total == 0
      map["thread"] = thread
      map["sender_name"] = sender_name
      map["reciver"] = reciver
      map["message"] = message
      map["m_id"] = m_id
      map["type"] = fileType
      if (forward != "") {
          map["replay_of"] = forward
          map["replay"] = forward
      }


      val utcFormat: DateFormat = SimpleDateFormat("yyyy-MM-dd HH:mm:ss")
      utcFormat.timeZone = TimeZone.getTimeZone("UTC")

      val date = utcFormat.format(Date())
      map["date"] = date
 */
    const uthHeader = me?.email;
    const formData = new FormData();
    //formData.append('id',user?.id);
    formData.append("sender", me?.id);
    formData.append("is_1st", messages.length == 0);
    formData.append("thread", thread);
    formData.append("sender_name", me?.name);
    formData.append("reciver", user?.id);
    formData.append("message", messge.message);
    formData.append("m_id", id.toString());
    formData.append("type", messge?.type);
    formData.append("date", date);

    if (forward != "") {
      formData.append("replay_of", "");
      formData.append("replay", "");
    }
    if (selectedFile) {
      formData.append("attachment", selectedFile);
    }

    PostRequestForm(API_ROUTES.SEND_MESSAGE, formData, null, "", (res) => {
      if (res.error) {
        console.log(res.error)
      } else {
        if (messge?.type != "T") {
          sendMessageSocket(messge, user?.id);
        }
      }
    });
  };

  const getMessage = (thread) => {
    if (user != null && user != "") {
      const uthHeader = user?.email;
      const formData = new FormData();
      formData.append("thread", thread);
      PostRequestForm(
        API_ROUTES.GET_ALL_CHATS_BY_THREAD,
        formData,
        null,
        uthHeader,
        (res) => {
          if (res.error) {
            //  console.log(res);
          } else {
            //console.log(res);
            if (res.code == "-200") {
              ///console.log(res);
              setMessages(res?.chats);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    setMessages([]);
    if (user != null && user != "") {
      var t_thread = "";
      if (me?.id > user?.id) {
        t_thread = me?.id + "" + user?.id;
      } else {
        t_thread = user?.id + "" + me?.id;
      }
      setThread(t_thread);

      //console.log("thread", t_thread);

      getMessage(t_thread);
      return () => {
        setMessages([]);
      };
    }
  }, [user]);

  useEffect(() => {
    if (messages.length > 3) {
      if (messageListRef.current) {
        messageListRef.current.scrollTo({
          top: messageListRef.current.scrollHeight,
          behavior: "smooth",
        });
        //messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (newmess != null && newmess != "") {
      setMessages([...messages, newmess]);
    }
  }, [newmess]);

  const encrypt = (passphrase, data) => {
    const key = getKey(passphrase);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      mode: CryptoJS.mode.ECB,
    });
    return encrypted.toString();
  };
  const getKey = (passphrase) => {
    return CryptoJS.SHA256(passphrase);
  };
  // Decrypt a string
  const decrypt = (passphrase, encryptedData) => {
    const key = getKey(passphrase);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      mode: CryptoJS.mode.ECB,
    });
    const str = decrypted.toString(CryptoJS.enc.Utf8);
    return str == "" ? encryptedData : str;
  };

  const RenderMessage = useCallback(
    ({ message }) => (
      <div
        className={`chat-item ${
          message.sender == me?.id ? "sender" : "receiver"
        }`}
      >
        <div className="chat-bubble">
          <p className="chat-image">
            {message?.type == "I" ? (
              <img
                src={
                  message?.attachment_my
                    ? message.attachment_my
                    : URLS.Attachment_URL + message.attachment
                }
                className="chat-image"
              />
            ) : null}
            {message?.type == "P" ? (
              <PDFViewr
                pdf={
                  message?.attachment_my
                    ? message.attachment_my
                    : URLS.Attachment_URL + message.attachment
                }
              />
            ) : null} 
             {message?.type == "V" ? (
              <VideoViewer
                video={
                  message?.attachment_my
                    ? message.attachment_my
                    : URLS.Attachment_URL + message.attachment
                }
              />
            ) : null}
          </p>

          <p className="chat-message">
            {decrypt(message?.sender, message?.message)}
          </p>
          <div className="chat-info">
            <span className="chat-time">
              {FormateDate(message?.created_at)}
              {"       "}
              {message?.type == "I"
                ? "IMAGE"
                : message?.type == "P"
                ? "PDF"
                : ""}
            </span>
            {message.sender == me?.id && (
              <span className="chat-status">
                <StatusIcon status={message?.seen?.toString()} />
              </span>
            )}
          </div>
        </div>
      </div>
    ),
    [messages]
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const renderFilePreview = () => {
    if (!selectedFile) return null;

    const fileType = selectedFile.type;

    if (fileType.startsWith("image/")) {
      // Render the image preview
      return (
        <div className="file-preview">
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Selected file"
            className="preview-image"
          />
          <span className="file-name">{selectedFile.name}</span>
          <img
            onClick={() => {
              setSelectedFile(null);
            }}
            src={ImagePaths.cross.default}
            alt="Document icon"
            className="cancel-button"
            style={{
              width: "15px", // Adjust width and height as needed
              height: "15px", // Maintain aspect ratio
            }}
          />
        </div>
      );
    } else if (fileType.startsWith("video/")) {
      // Render the image preview
      return (
        <div className="file-preview">
          <img
          src={ImagePaths.attachment.default}
            alt="Selected file"
            className="preview-image"
          />
          <span className="file-name">{selectedFile.name}</span>
          <img
            onClick={() => {
              setSelectedFile(null);
            }}
            src={ImagePaths.cross.default}
            alt="Document icon"
            className="cancel-button"
            style={{
              width: "15px", // Adjust width and height as needed
              height: "15px", // Maintain aspect ratio
            }}
          />
        </div>
      );
    } else if (
      fileType === "application/pdf" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      // Render the icon for PDF or DOCX
      return (
        <div className="file-preview">
          <img
            src={ImagePaths.attachment.default}
            alt="Document icon"
            className="preview-icon"
          />
          <span className="file-name">{selectedFile.name}</span>
          <img
            onClick={() => {
              setSelectedFile(null);
            }}
            src={ImagePaths.cross.default}
            alt="Document icon"
            className="cancel-button"
            style={{
              width: "15px", // Adjust width and height as needed
              height: "15px", // Maintain aspect ratio
             
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="messaging">
      <div className="recipient-info">
        <img
          src={API_ROUTES.PROFILE_PIC + user?.profile_pic}
          alt="S"
          className="profile-image"
        />
        <div className="recipient-details">
          <div className="recipient-name">{user?.name}</div>
          <div className="recipient-email">{user?.email}</div>
        </div>
      </div>
      <div className="message-list" ref={messageListRef}>
        {messages.map((message, index) => (
          <RenderMessage message={message} key={index} />
        ))}
      </div>

      {selectedFile && (
        <div className="file-selector-container">{renderFilePreview()}</div>
      )}

      <div className="message-input">
        <label htmlFor="file-upload">
          <img
            src={ImagePaths.attachment.default}
            alt="Select file"
            style={{ height: "20px", width: "20px", marginRight: "10px" }}
          />
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".jpg,.jpeg,.png,.pdf,.mp4" // Specify accepted file types
          style={{ display: "none" }}
          onChange={handleFileInputChange}
        />
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
});

export default Messaging;
