import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Home.css";
import { Sessions } from "../../Constants/Sessions";
import { API_ROUTES } from "../../Constants/apiRouts";
import Dashboard from "./Dashboard";


const Home = () => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [user, setUser] = useState("");

  const handleProfileClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem(Sessions.User);
    localStorage.removeItem(Sessions.LoggedIn);
    navigate("/login");
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem(Sessions.User)));
    //console.log(user);
  }, []);


  return (
    <div>
      <nav className="navbar">
        <div className="navbar-brand">Easysent</div>
        <div className="navbar-profile">
          <img
            src={API_ROUTES.PROFILE_PIC + user?.profile_pic}
            alt="S"
            onClick={handleProfileClick}
          />
          {dropdownVisible && (
            <div className="dropdown-menu">
              <div className="dropdown-header">
                <p>{user.name}</p>
                <p>{user.email}</p>
              </div>
              <ul>
                <li>Profile</li>
                <li>Settings</li>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          )}
        </div>
      </nav>

      <Dashboard me={user} />
    </div>
  );
};





export default Home;

