import React from 'react';

const StatusIcon = ({ status }) => {
  switch (status) {
    case "0":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" className="animate-spin">
          <circle cx="12" cy="12" r="10" stroke="gray" strokeWidth="2" fill="none" />
          <path d="M12 2 A10 10 0 0 1 22 12" stroke="gray" strokeWidth="2" fill="none" />
        </svg> // Clock Icon
      );
    case "1":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M9 12l2 2l4-4" stroke="gray" strokeWidth="2" fill="none" />
        </svg> // Single Tick
      );
    case "2":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M7 13l3 3l7-7" stroke="gray" strokeWidth="2" fill="none" />
          <path d="M13 13l3 3l4-4" stroke="gray" strokeWidth="2" fill="none" />
        </svg> // Double Tick
      );
    case "3":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M7 13l3 3l7-7" stroke="blue" strokeWidth="2" fill="none" />
          <path d="M13 13l3 3l4-4" stroke="blue" strokeWidth="2" fill="none" />
        </svg> // Double Blue Tick
      );
    default:
      return null;
  }
};

export default StatusIcon;
