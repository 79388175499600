import React from "react";
import "./EmptyChatContainer.css";
import logo from "../../../../Constants/Images/ic_launcher_round.png"


const EmptyChatContainer = () => {
  const version  = "v0.01";
  return (
    <div className="empty-chat-container">
      <div className="empty-chat-content">
        <img src={logo} alt="Chat Icon" className="chat-icon" />
        <h2 className="welcome-message">Welcome to Easysent</h2>
        <p className="prompt-message">Select a conversation or start a new chat to begin messaging.</p>
        <text style={{position:'absolute', bottom:-20, right:40}}>{version}</text>
      </div>
    </div>
  );
};

export default EmptyChatContainer;