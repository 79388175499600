export { default as Logo } from './ic_launcher.png';
export { default as Logo_round } from './ic_launcher_round.png';
export { default as Logo_fore } from './ic_launcher_foreground.png';
export { default as Cross } from './cross.svg';


export const ImagePaths = {
    Logo: require('./ic_launcher.png'),
    LogoRound: require('./ic_launcher_round.png'),
    Logo_fore: require('./ic_launcher_foreground.png'),
    cross: require('./cross.svg'),
    user: require('./user.svg'),
    chat: require('./chat.svg'),
    bar: require('./bar.svg'),
    arrow_left: require('./arrow_left.svg'),
    search: require('./search.svg'),
    attachment: require('./attachment.svg'),
    open: require('./open.svg'),
  };
  