import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/login.css";
import { Logo, Logo_round } from "../../Constants/Images/Images";
import { PostRequestForm } from "../../Network/ApiRequests";
import { API_ROUTES } from "../../Constants/apiRouts";
import CustomProgressBar from "../../Components/CustomProgressBar";
import Alert from "../../Components/Alert";
import { Sessions } from "../../Constants/Sessions";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [alert, setAlert] = useState({ type: "", message: "", show: false });

  const userAgent = navigator.userAgent;
  const deviceModel = userAgent.match(/\((.*?)\)/)[1]; // Extract text inside parentheses
  const manufacturer = userAgent.split(";")[1].trim(); // Extract manufacturer info

  const showAlert = (type, message) => {
    setAlert({ type, message, show: true });
    setTimeout(() => setAlert({ type: "", message: "", show: false }), 3000); // Auto close after 3 seconds
  };

  const handleLogin = () => {
    const formData = new FormData();
    formData.append("password", password);
    formData.append("username", username);
    formData.append("token", ""); // Assuming token is empty string initially
    formData.append("device", manufacturer);
    formData.append("model", deviceModel);
    formData.append("device_id", "WEB");

    PostRequestForm(API_ROUTES.LOGIN, formData, setLoading, "", (res) => {
      if (res.error) {
        showAlert("error", res.error);
      } else {
        // {code, message, login_token, user} = res

        //console.log(res);
        if (res.code == "-100") {
          showAlert("error", res.message);
          const userData = {
            email: res?.email,
          };

          navigate("/verify", { state: { userData } });
        } else if (res.code == "-200") {
          showAlert("success", res.message);
          localStorage.setItem(Sessions.User, JSON.stringify(res.user));
          localStorage.setItem(Sessions.LoggedIn, "true");
          navigate("/home");
        }
      }
    });
  };

  const navigators=(path)=>{
    navigate(path);
  }

  return (
    <div className="login-container">
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ type: "", message: "", show: false })}
        />
      )}

      <div className="login-content">
        <div className="login-form">
          <img src={Logo_round} alt="App Logo" className="app-logo" />
          <h2 className="login-heading">Login</h2>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <div className="form-group">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="login-input"
                placeholder="Enter your username"
                required
              />
            </div>
            <CustomProgressBar visible={loading} />
            <div className="form-group">
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
                placeholder="Enter your password"
                required
              />
            </div>
            <button type="submit" className="login-button">
              Login
            </button>
          </form>
          <p className="signup-link">
            Don't have an account? <a onClick={()=>{navigators("/signup")}}>Sign up here</a>
          </p>
          <p className="signup-link">
            <a onClick={()=>{navigators("/forgotpassword")}}>Forgot Password?</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
