import React, { useEffect, useState } from "react";
import "./user.css";

import { ImagePaths, Logo, Logo_round } from "../../../Constants/Images/Images";
import { Sessions } from "../../../Constants/Sessions";
import { PostRequestForm } from "../../../Network/ApiRequests";
import { API_ROUTES } from "../../../Constants/apiRouts";
import Alert from "../../../Components/Alert";

export default function Users({setMuser}) {
  const [searchText, setSearchText] = useState("");
  const [user, setUser] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "", show: false });
  const showAlert = (type, message, callback) => {
    setAlert({ type, message, show: true });
    setTimeout(() => {
      setAlert({ type: "", message: "", show: false });
      if (callback) {
        callback();
      }
    }, 1000); // Auto close after 3 seconds
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    console.log("Search for:", searchText);
  };

  const [data,setData] = useState([]) 

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem(Sessions.User)));
  }, []);

  useEffect(() => {
    if (user != null && user != "") {
      const uthHeader = user?.email;
      const formData = new FormData();
      PostRequestForm(
        API_ROUTES.GET_ALL_USER,
        formData,
        null,
        uthHeader,
        (res) => {
          if (res.error) {
            showAlert("error", res.error);
          } else {
            //console.log(res);
            if (res.code == "-200") {
              setData(res?.users);
            }
          }
        }
      );
    }
  }, [user]);

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase()) || item.email.toLowerCase().includes(searchText.toLowerCase())
  );
  const RowRenderer = ({ user }) => {
    return (
      <div className="list-item-container" onClick={()=>{
        if(setMuser){
          setMuser(user)
        }
      }}>
        <img  src={API_ROUTES.PROFILE_PIC  + user?.profile_pic} alt="image" className="user-image" />
        <div className="user-data">
          <text className="name">{user.name}</text>
          <text className="email">{user.email}</text>
        </div>
      </div>
    );
  };

  return (
    <div>
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ type: "", message: "", show: false })}
        />
      )}
      <div className="search-box">
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleInputChange}
        />
        <button className="search-button" onClick={handleSearch}>
          <img
            src={ImagePaths.search.default}
            style={{ height: "26px", width: "15px" }}
          />
        </button>
      </div>
      {/* list */}
      {filteredData.map((user, index) => {
        return <RowRenderer key={index} user={user} index={index} />;
      })}
    </div>
  );
}
