import React, { useState, useEffect } from "react";
import { Logo_round } from "../../Constants/Images/Images";

import "../../css/otp.css";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../Constants/apiRouts";
import { PostRequestForm } from "../../Network/ApiRequests";
import Alert from "../../Components/Alert";
const OtpVerification = () => {
  const [otp, setOtp] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [alert, setAlert] = useState({ type: "", message: "", show: false });

  const [code, setCode] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.userData?.code) {
      setCode(location?.state?.userData?.code);
    }
  }, []);

  const showAlert = (type, message, callback) => {
    setAlert({ type, message, show: true });
    setTimeout(() => {
      //setAlert({ type: "", message: "", show: false });
      if (callback) {
        callback();
      }
    }, 1000); // Auto close after 3 seconds
  };
  // Countdown timer effect
  useEffect(() => {
    let timer;
    if (resendDisabled && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setResendDisabled(false); // Enable resend button after countdown ends
    }
    return () => clearTimeout(timer);
  }, [resendDisabled, countdown]);

  const handleVerify = (e) => {
    e.preventDefault();
    // Perform OTP verification logic here (e.g., API call)
    if (otp == code) {
      verify();
    } else {
      showAlert("error", "Invalid OTP. Please enter the correct OTP.");
    }
  };

  const handleResend = () => {
    resend();
    setResendDisabled(true);
    setCountdown(30); // Reset countdown timer
  };

  const resend = () => {
    const formData = new FormData();
    formData.append("email", location?.state?.userData?.email);

    PostRequestForm(API_ROUTES.RESEND_OTP, formData, null, "", (res) => {
      if (res.error) {
        showAlert("error", res.error);
      } else {
        // console.log(res)
        setCode(res?.otp);
        // {code, message, login_token, user} = res
        showAlert("success", res?.success);
      }
    });
  };

  const verify = () => {
    const formData = new FormData();
    formData.append("email", location?.state?.userData?.email);

    PostRequestForm(API_ROUTES.EMAIL_VERIFY, formData, null, "", (res) => {
      if (res.error) {
        showAlert("error", res.error);
      } else {
        //console.log(res);
        if (res.code == "-200") {
          showAlert("success", res.success, () => {
            if (location?.state?.userData?.from == "forgot") {
              const userData = {
                email: location?.state?.userData?.email,
              };
              navigate("/setpassword", { state: { userData } });
            } else {
              navigate("/login");
            }
          });
        }
      }
    });
  };

  return (
    <div className="otp-container">
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ type: "", message: "", show: false })}
        />
      )}

      <div className="otp-content">
        <img src={Logo_round} alt="App Logo" className="app-logo" />
        <h2>OTP Verification</h2>
        <p>
          An OTP has been sent to {location?.state?.userData?.email}. Please
          enter it below:
        </p>
        <form onSubmit={handleVerify}>
          <div className="form-group">
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              maxLength={6} // Assuming OTP length is 6 digits
              className="otp-input"
              placeholder="Enter OTP"
              required
            />
          </div>
          <button type="submit" className="otp-button">
            Verify OTP
          </button>
        </form>
        <div className="resend-section">
          <p>
            Didn't receive OTP?{" "}
            {resendDisabled ? (
              `Resend OTP in ${countdown} seconds`
            ) : (
              <button onClick={handleResend} disabled={resendDisabled}>
                Resend OTP
              </button>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
