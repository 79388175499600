import React, { useEffect, useState } from "react";
import "../css/splashCss.css"; // Add this for styling
import { useNavigate } from "react-router-dom";
import { Sessions } from "../Constants/Sessions";
import { Logo } from "../Constants/Images/Images";

const SplashScreen = ({ onLoaded }) => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleNavigate = () => {
    let islogg = localStorage.getItem(Sessions.LoggedIn);

    if (islogg === "true") {
      navigate("/home");
    } else {
      navigate("/login"); // Navigate to '/about' route
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      if (onLoaded) onLoaded();
      handleNavigate();
    }, 1500); // Splash screen will be visible for 3 seconds

    return () => clearTimeout(timer);
  }, [onLoaded]);

  if (!loading) {
    return null; // You can replace this with the main component or homepage
  }

  return (
    <div className="splash-screen">
      <img src={Logo} alt="App Logo" className="logo" />{" "}
      {/* Replace with your logo */}
      <h1>Welcome to Easysent</h1>
    </div>
  );
};

export default SplashScreen;
